footer[data-v-8349841e] {
  background-color: #0270c1;
  height: 100px;
  text-align: center;
  color: white;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
footer p[data-v-8349841e] {
    text-align: center;
    font-size: xx-small;
}
footer .tips1[data-v-8349841e] {
    line-height: 50px;
}
footer .tips2[data-v-8349841e] {
    line-height: 30px;
}
